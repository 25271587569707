import React, { Component } from 'react';
import './NavbarStyle.scss'

class Navbar extends Component {

    render() {
        return (
            
            <nav class="navbar navbar-expand-lg navbar-dark" id='Home'>
                <div className='container'>
                    <img class="navbar-brand" href="#" src='/images/bnblogo.png' />
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#Home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#About">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#BgImage">Services</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#Events">Events</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#Clients">Clients</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#Gallery">Gallery</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#Contact_Us">Contact Us</a>
                        </li>
                    </ul>
                </div>

                </div>
            </nav>
        )
    }
}

export default Navbar;
import React, { Component } from "react";
import "./ServicesStyle.scss";
import Slider from "react-slick";

class Services extends Component {
  // setImg = (a) => {
  //   document.getElementById(
  //     "BgImage"
  //   ).style.background = `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('${document.getElementById("img_"+a).src}')`;
  //   //document.getElementById("ImageFrame").src = document.getElementById("img_"+a).src;
  //   document.getElementById('TextFrame').innerHTML = document.getElementById("text_"+a).innerHTML;
  // };
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      updateCount: 0,
    };
    console.log(this.slider);
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      afterChange: () =>
        this.setState((state) => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    return (
      <div className="parallax3 text-aligning-white" id="BgImage">
        <div className="container">
          <div className="section1 mb-5">
            <div className="mb-3">
              <h3>OUR SERVICES</h3>
              <img src="images/underline.png" />
            </div>

            <div className="services-main-content" id="TextFrame">
              <img
                src="images/services_icons/animation_icon.png"
                className="img-fluid mx-auto mini-icon my-2"
              />
              <p className="mb-2 mb-lg-5">Musical Concert</p>
              <p>
                We are passionate about tamil music and we aspire in taking it
                to global platforms. From organising the artists, selling
                tickets to hosting the Event with utmost elegance and grandeur,
                band-b entertainments got you completely covered on all aspects.
                We organise shows around the United States of America and also
                propose to handle complete artist management.
              </p>
            </div>
          </div>

          <div className="section2 pt-5">
            <Slider ref={(slider) => (this.slider = slider)} {...settings}>
              <div
                className="services-header col-6 col-lg-3 mb-3 px-3"
                onClick={() => this.props.setImg("4")}
              >
                <img
                  src="images/services_icons/musical_concert_bg.jpg"
                  id="img_4"
                  className="img-fluid"
                />
                <div className="services-content" id="text_4">
                  <img
                    src="images/services_icons/musical_concert_icon.png"
                    className="img-fluid mx-auto mini-icon my-1 mb-lg-4"
                  />
                  <p className="mb-1 mb-lg-5">Musical Concert</p>
                  <p className="d-none" id="hidden_4">
                    We are passionate about tamil music and we aspire in taking
                    it to global platforms. From organising the artists, selling
                    tickets to hosting the Event with utmost elegance and
                    grandeur, band-b entertainments got you completely covered
                    on all aspects. We organise shows around the United States
                    of America and also propose to handle complete artist
                    management.{" "}
                  </p>
                </div>
              </div>

              <div
                className="services-header col-6 col-lg-3 mb-3 px-3"
                onClick={() => this.props.setImg("2")}
              >
                <img
                  src="images/services_icons/events_bg.jpg"
                  id="img_2"
                  className="img-fluid"
                />
                <div className="services-content" id="text_2">
                  <img
                    src="images/services_icons/events_icon.png"
                    className="img-fluid mx-auto mini-icon my-2"
                  />
                  <p className="mb-2 mb-lg-5">Events</p>
                  <p className="d-none" id="hidden_2">
                    We conceptualise, plan, and manage every project from
                    inception to completion, specialising in the development of
                    exceptional events for private and corporate clients. Our
                    group of seasoned professionals understands that
                    breakthrough ideas are only as good as the discipline
                    supporting them. At B&B, we believe that there is a sublime
                    connection between the mastery of logistics and the creation
                    of astonishment. Our events are completely customized,
                    reflecting the brand personality of each client{" "}
                  </p>
                </div>
              </div>

              <div
                className="services-header col-6 col-lg-3 mb-3 px-3"
                onClick={() => this.props.setImg("1")}
              >
                <img
                  src="images/services_icons/marketing_bg.jpg"
                  id="img_1"
                  className="img-fluid"
                />
                <div className="services-content" id="text_1">
                  <img
                    src="images/services_icons/marketing_icon.png"
                    className="img-fluid mx-auto mini-icon my-2"
                  />
                  <p className="mb-2 mb-lg-5">Marketing</p>
                  <p className="d-none" id="hidden_1">
                    We guarantee to offer concrete and REAL outcomes using
                    industrial and the latest marketing approaches when it comes
                    to music marketing. With an overall combined experience of
                    over 50 years in world-class music marketing organisations,
                    corporations, radio stations, and television stations, Our
                    staff know what they're talking about. Allow us to help you
                    increase your event reach, fan engagement and growth by
                    speeding up your marketing.
                  </p>
                </div>
              </div>

              <div
                className="services-header col-6 col-lg-3 mb-3 px-3"
                onClick={() => this.props.setImg("3")}
              >
                <img
                  src="images/services_icons/movie_bg.jpg"
                  id="img_3"
                  className="img-fluid"
                />
                <div className="services-content" id="text_3">
                  <img
                    src="images/services_icons/movie_icon.png"
                    className="img-fluid mx-auto mini-icon my-2"
                  />
                  <p className="mb-3 mb-lg-5">Movies</p>
                  <p className="d-none" id="hidden_3">
                    Media production and management never got simpler before!
                    B&B brings in a vast experience in the field of filmmaking
                    and production to the table. Our talented crew would assist
                    you with script development, budgeting, production,
                    post-production, marketing, and distribution with the
                    assurance of shandholding you in every step. Movie
                    Production & Release
                  </p>
                </div>
              </div>

              <div
                className="services-header col-6 col-lg-3 mb-3 px-3"
                onClick={() => this.props.setImg("0")}
              >
                <img
                  src="images/services_icons/animation_bg.jpg"
                  id="img_0"
                  className="img-fluid services-header-image"
                />
                <div className="services-content" id="text_0">
                  <img
                    src="images/services_icons/animation_icon.png"
                    className="img-fluid mx-auto mini-icon my-2"
                  />
                  <p className="mb-3 mb-lg-5">Animation</p>
                  <p className="d-none" id="hidden_0">
                    There are many Animation Companies and yet we stand tall on
                    Creativity and Focused content generation towards our target
                    audience. We make Custom Animated Videos across the
                    industry. In our Animation Studio, we produce 2D & 3D
                    Animation Services at affordable cost. If you are looking to
                    make animated videos for your organisation, look no further!
                  </p>
                </div>
              </div>

              <div
                className="services-header col-6 col-lg-3 mb-3 px-3"
                onClick={() => this.props.setImg("5")}
              >
                <img
                  src="images/services_icons/training_bg.jpg"
                  id="img_5"
                  className="img-fluid"
                />
                <div className="services-content" id="text_5">
                  <img
                    src="images/services_icons/training_icon.png"
                    className="img-fluid mx-auto mini-icon my-2 mb-lg-4"
                  />
                  <p className="mb-2 mb-lg-4">B&B's Production and Training</p>
                  <p className="d-none" id="hidden_5">
                    The promise of tomorrow starts with today. With a talented
                    pool of inhouse experts, Band-B productions would completely
                    hand hold you in the entire process of managing and
                    overseeing the recording and production of a track – from
                    writing the first lyrics and melodies to mastering the
                    finished track. There are various different forms of
                    production, from track production to toplining, or even
                    solely mixing and mastering where B&B would help you achieve
                    it effortlessly.
                  </p>
                </div>
              </div>

              <div
                className="services-header col-6 col-lg-3 mb-3 px-3"
                onClick={() => this.props.setImg("6")}
              >
                <img
                  src="images/services_icons/tv_bg.jpg"
                  id="img_6"
                  className="img-fluid"
                />
                <div className="services-content" id="text_6">
                  <img
                    src="images/services_icons/tv_icon.png"
                    className="img-fluid mx-auto mini-icon my-2"
                  />
                  <p className="mb-3 mb-lg-5">B&B TV</p>
                  <p className="d-none" id="hidden_6">
                    With our expertise and experience across various media
                    houses and process, we are now here to offer you regional
                    tamil content live streaming on our exclusive channel
                    online. Get Exclusive access to multiple series and movies
                    through live telecast on our network
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;

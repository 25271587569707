import React, { Component } from "react";
import "./CarouselStyle.scss";

class Carousel extends Component {
  render() {
    return (
      <div className="parallax1">
         <div className="d-flex header-container"> 
          <p className="header-text">  We conceptualise, plan, and manage every project from inception to completion, specialising in the development of exceptional events for private and corporate clients. Our group of seasoned professionals understands that breakthrough ideas are only as good as the discipline supporting them. At B&B, we believe that there is a sublime connection between the mastery of logistics and the creation of astonishment. Our events are completely customized, reflecting the brand personality of each client</p>
          {/* <div
            id="carouselExampleControls"
            class="carousel slide w-100"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item text-light active">
                <p className="w-50 mx-auto">
                We conceptualise, plan, and manage every project from inception to completion, specialising in the development of exceptional events for private and corporate clients. Our group of seasoned professionals understands that breakthrough ideas are only as good as the discipline supporting them. At B&B, we believe that there is a sublime connection between the mastery of logistics and the creation of astonishment. Our events are completely customized, reflecting the brand personality of each client
                </p>
              </div>
              <div class="carousel-item text-light">
                <p className="w-50 mx-auto align-text-top">
                We guarantee to offer concrete and REAL outcomes using industrial and the latest  marketing approaches when it comes to music marketing. With an overall combined experience of over 50 years in world-class music marketing organisations, corporations, radio stations, and television stations, Our  staff know what they're talking about. Allow us to help you increase your event reach, fan engagement and growth by speeding up your marketing.
                </p>
              </div>
              <div class="carousel-item text-light">
                <p className="w-50 mx-auto align-middle">
                There are many Animation Companies and yet  we stand tall on Creativity and Focused content generation towards  our target audience. We make Custom Animated Videos across the industry. In our Animation Studio, we produce 2D & 3D Animation Services at affordable cost.  If you are looking to make animated videos for your organisation, look no further!
                </p>
              </div>
            </div>

            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div> */}
        </div>
     </div>
    );
  }
}
export default Carousel;

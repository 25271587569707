import React, { Component } from "react";
import "./SponsorsStyle.scss";
import "../Concert.css";
import Slider from "react-slick";

class Sponsors extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
    };
    return (
      <div className="text-aligning-white sponsors-section parallax2 pt-5" id="Clients">
        <h3>SPONSORS</h3>
        <img className="mb-3" src="images/underline.png" />

        <div className="container">
          <div className="row">
            <div className="col-3 col-lg-3 mb-5">
              <img
                className="sponsor-display d-block w-100"
                src="/images/SponsorsLogo/logo1.png"
              />
            </div>
            <div className="col-3 col-lg-3 mb-5">
              <img
                className="sponsor-display d-block w-100"
                src="/images/SponsorsLogo/logo2.png"
              />
            </div>
            <div className="col-3 col-lg-3 mb-5">
              <img
                className="sponsor-display d-block w-100"
                src="/images/SponsorsLogo/logo3.png"
              />
            </div>
            <div className="col-3 col-lg-3 mb-5">
              <img
                className="sponsor-display d-block w-100"
                src="/images/SponsorsLogo/logo4.png"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sponsors;

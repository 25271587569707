import React, {useState} from 'react';
import './EventsStyle.scss';
import '../Concert.css';
import { Component } from 'react';



class Events1 extends Component {

myFunction = () => {
        document.getElementById("eventsSelection").classList.toggle("showButton");
        this.setState({showSection: !this.state.showSection});
    }

    constructor(props) {
        super(props);
        this.state = { showSection:false }
    }
    render()
{

         return (
                <div className='text-aligning-black' id='Events'>
                    <h3>UPCOMING EVENTS</h3>
                    <img style={{ paddingBottom: 40 }} src='images/underline.png' />
                    <div className='container' id='eventsSelection'>
                        <div className="row">
                            <div className="col-sm-10 col-lg-4 pt-5">
                                <p className='concertName'>LONDON MUSIC NIGHT</p>
                                <p>SEP 17, 06:00 PM</p>
                                <button className='btn'>TICKET</button>
                            </div>
                            <div className="col-sm-10 col-lg-4 pt-5">
                                <p className='concertName'>LONDON MUSIC NIGHT</p>
                                <p>SEP 17, 06:00 PM</p>
                                <button className='btn'>TICKET</button>
                            </div>
                            <div className="col-sm-10 col-lg-4 pt-5">
                                <p className='concertName'>LONDON MUSIC NIGHT</p>
                                <p>SEP 17, 06:00 PM</p>
                                <button className='btn'>TICKET</button>
                            </div>
                            <div className="col-sm-10 col-lg-4 pt-5">
                                <p className='concertName'>LONDON MUSIC NIGHT</p>
                                <p>SEP 17, 06:00 PM</p>
                                <button className='btn'>TICKET</button>
                            </div>
                            <div className="col-sm-10 col-lg-4 pt-5">
                                <p className='concertName'>LONDON MUSIC NIGHT</p>
                                <p>SEP 17, 06:00 PM</p>
                                <button className='btn'>TICKET</button>
                            </div>
                            <div className="col-sm-10 col-lg-4 pt-5">
                                <p className='concertName'>LONDON MUSIC NIGHT</p>
                                <p>SEP 17, 06:00 PM</p>
                                <button className='btn'>TICKET</button>
                            </div>

                        </div>
                    </div>



                    <button className='show-all-btn' onClick={this.myFunction}>{this.state.showSection ? "HIDE ALL EVENTS" : "SHOW ALL EVENTS" }</button>


                </div>



            )
    }
}





    export default Events1;
import React, { Component } from "react";
import './FooterStyle.scss'
import { TwitterClient } from 'twitter-api-client';



class Footer extends Component {
    // setImg = (a) => {
    //     document.getElementById(
    //       "BgImage"
    //     ).style.background = `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('${document.getElementById("img_"+a).src}')`;
    //     //document.getElementById("ImageFrame").src = document.getElementById("img_"+a).src;
    //     document.getElementById('TextFrame').innerHTML = document.getElementById("text_"+a).innerHTML;
    //   };

    render() {
    return (

        <div>

            <div className='text-aligning-black newsletter'>
                <h3>SUBSCRIBE TO NEWSLETTER</h3>
                <img className='pb-5' src='images/underline.png' />

                <div className='subscribe mb-0 mb-sm-3'>
                    <input type="email" placeholder="Enter your email"  />
                    <div><button className='btn-danger'>Notify me</button></div>
                </div>
            </div>

            <div className='footer-content d-none d-lg-block'>
                <div className='parallax5'>
                    <div className='text-aligning-white'>
                        <h3>WE ARE SOCIAL</h3>
                        <img className='pb-3' src='images/underline.png' />

                        <div>
                            <img className='icons-display-blueBird' src='/images/twitter_blue_bird.png' />
                            <p style={{color:'gray'}}>about 30 days ago</p>
                            <p style={{fontWeight:'bold'}}>Why NASA is building a gigantic telescope on the far side of the Moon…</p>
                            {/* <a class="twitter-follow-button" href="https://twitter.com/TwitterDev">hu</a> */}
                            <a href="https://twitter.com/AbineshTamil890?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="true" data-size="large">Follow @AbineshTamil890</a>
                        </div>
                    </div>

                        
                    <div className='container Footer-Links'>
                        <div className="row">
                            <div className="col-md-4 col-lg-4 col-sm-3 items">
                                <p className='headings'>Links</p>
                                    <a href="#Home">Home</a>
                                    <a href="#Events">Events</a>
                                    <a href="#About">About Us</a>
                                    <a href="#Gallery">Gallery</a>
                                    <a href="#BgImage">Services</a>
                                    <a href="#Clients">Clients</a>
                                    <a href="#Contact_Us">Contact Us</a>
                            </div>

                            <div className="col-md-4 col-lg-4 col-sm-3 items">
                                <p className="headings">Services</p>
                                    <a href="#BgImage" onClick={() => this.props.setImg("0")}>Animation</a>
                                    <a href="#BgImage" onClick={() => this.props.setImg("1")}>Marketing</a>
                                    <a href="#BgImage" onClick={() => this.props.setImg("2")}>Events</a>
                                    <a href="#BgImage" onClick={() => this.props.setImg("3")}>Movies</a>
                                    <a href="#BgImage" onClick={() => this.props.setImg("4")}>Mucial Concert</a>
                                    <a href="#BgImage" onClick={() => this.props.setImg("5")}>B&B's Production</a>   
                                    <a href="#BgImage" onClick={() => this.props.setImg("6")}>B&B TV</a>  
                            </div>


                            <div className="col-md-4 col-lg-4 col-sm-3 items pb-5">
                                <p className="headings">Quick Contact</p>
                                <p className='pb-3'>Fremont, California 94539, 408-230-5210, info@bandb.com</p>
                                
                                <div className='d-flex flex-row'>
                                    <div className='col-sm mx-2'>
                                        <img src='/images/twitter_icon.png'  />
                                    </div>
                                    <div className='col-sm mx-2'>
                                        <img src='/images/facebook_icon.png'  />
                                    </div>
                                    <div className='col-sm mx-2'>
                                        <img src='/images/linkedin_icon.png'  />
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <div className='d-block d-lg-none'> 

                    <div class="accordion accordion-flush" id="accordionFlushExample" >
                        <div className='parallax5'>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    LINKS
                                    </button>
                                </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                            <div class="accordion-body">
                                                <div className="items">
                                                    <a href="#Home">Home</a>
                                                    <a href="#Events">Events</a>
                                                    <a href="#About">About Us</a>
                                                    <a href="#Gallery">Gallery</a>
                                                    <a href="#BgImage">Services</a>
                                                    <a href="#Clients">Clients</a>
                                                    <a href="#Contact_Us">Contact Us</a>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        SERVICES
                                    </button>
                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <div className="items">
                                                <a href="#BgImage" onClick={() => this.props.setImg("0")}>Animation</a>
                                                <a href="#BgImage" onClick={() => this.props.setImg("1")}>Marketing</a>
                                                <a href="#BgImage" onClick={() => this.props.setImg("2")}>Events</a>
                                                <a href="#BgImage" onClick={() => this.props.setImg("3")}>Movies</a>
                                                <a href="#BgImage" onClick={() => this.props.setImg("4")}>Mucial Concert</a>
                                                <a href="#BgImage" onClick={() => this.props.setImg("5")}>B&B's Production</a>   
                                                <a href="#BgImage" onClick={() => this.props.setImg("6")}>B&B TV</a> 
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        QUICK CONTACT
                                    </button>
                                    </h2>
                                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body">
                                        <p className='pb-3'>Fremont, California 94539, 408-230-5210, info@bandb.com</p>
                                        
                                        <div className='d-flex flex-row justify-content-center'>
                                            <div className='col-sm mx-2'>
                                                <img src='/images/twitter_icon.png'  />
                                            </div>
                                            <div className='col-sm mx-2'>
                                                <img src='/images/facebook_icon.png'  />
                                            </div>
                                            <div className='col-sm mx-2'>
                                                <img src='/images/linkedin_icon.png'  />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div> 
        )
    }
}

export default Footer;
import React, { Component } from 'react'
import './GalleryStyle.scss';
import '../Concert.css';


class Gallery extends Component {

    render() {

        return (
            <div className='Gallery-Section pt-3' id='Gallery'>
                
                <div className='text-aligning-black pb-5'>
                    <h3>GALLERY</h3>
                    <img className='pb-5' src='images/underline.png' />
                </div>
                
                <div className="row gallery-parent" id="gallery" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <div className="gallery-img col-6 col-sm-6 col-lg-3 mb-3">
                        <img className="w-100" src="images/gallery_images/gallery_1.jpeg" data-bs-target="#carouselExample" data-bs-slide-to="0" />
                    </div>
                    <div className="gallery-img col-6 col-sm-6 col-lg-3 mb-3">
                        <img className="w-100" src="images/gallery_images/gallery_2.jpeg" data-bs-target="#carouselExample" data-bs-slide-to="1" />
                    </div>
                    <div className="gallery-img col-6 col-sm-6 col-lg-3 mb-3">
                        <img className="w-100" src="images/gallery_images/gallery_3.jpeg" data-bs-target="#carouselExample" data-bs-slide-to="2" />
                    </div>
                    <div className="gallery-img col-6 col-sm-6 col-lg-3 mb-3">
                        <img className="w-100" src="images/gallery_images/gallery_4.jpeg" data-bs-target="#carouselExample" data-bs-slide-to="3" />
                    </div>
                    <div className="gallery-img col-6 col-sm-6 col-lg-3 mb-3">
                        <img className="w-100" src="images/gallery_images/gallery_5.jpeg" data-bs-target="#carouselExample" data-bs-slide-to="4" />
                    </div>
                    <div className="gallery-img col-6 col-sm-6 col-lg-3 mb-3">
                        <img className="w-100" src="images/gallery_images/gallery_6.jpeg" data-bs-target="#carouselExample" data-bs-slide-to="5" />
                    </div>
                    <div className="gallery-img col-6 col-sm-6 col-lg-3 mb-3">
                        <img className="w-100" src="images/gallery_images/gallery_7.jpeg" data-bs-target="#carouselExample" data-bs-slide-to="6" />
                    </div>
                    <div className="gallery-img col-6 col-sm-6 col-lg-3 mb-3">
                        <img className="w-100" src="images/gallery_images/gallery_8.jpeg" data-bs-target="#carouselExample" data-bs-slide-to="7" />
                    </div>
                </div>

                <div className="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                        <div className="modal-body">
                        
                            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
                            <ol className="carousel-indicators">
                                <li data-bs-target="#carouselExample" data-bs-slide-to="0" className="active" aria-current="true"></li>
                                <li data-bs-target="#carouselExample" data-bs-slide-to="1"></li>
                                <li data-bs-target="#carouselExample" data-bs-slide-to="2"></li>
                                <li data-bs-target="#carouselExample" data-bs-slide-to="3"></li>
                                <li data-bs-target="#carouselExample" data-bs-slide-to="4"></li>
                                <li data-bs-target="#carouselExample" data-bs-slide-to="5"></li>
                                <li data-bs-target="#carouselExample" data-bs-slide-to="6"></li>
                                <li data-bs-target="#carouselExample" data-bs-slide-to="7"></li>
                            </ol>
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                <img className="d-block w-100" src="images/gallery_images/gallery_3.jpeg" alt="First slide" />
                                </div>
                                <div className="carousel-item">
                                <img className="d-block w-100" src="images/gallery_images/gallery_1.jpeg" alt="Second slide" />
                                </div>
                                <div className="carousel-item">
                                <img className="d-block w-100" src="images/gallery_images/gallery_2.jpeg" alt="Third slide" />
                                </div>
                                <div className="carousel-item">
                                <img className="d-block w-100" src="images/gallery_images/gallery_3.jpeg" alt="Fourth slide" />
                                </div>
                                <div className="carousel-item">
                                <img className="d-block w-100" src="images/gallery_images/gallery_4.jpeg" alt="Fifth slide" />
                                </div>
                                <div className="carousel-item">
                                <img className="d-block w-100" src="images/gallery_images/gallery_5.jpeg" alt="Sixth slide" />
                                </div>
                                <div className="carousel-item">
                                <img className="d-block w-100" src="images/gallery_images/gallery_6.jpeg" alt="Seventh slide" />
                                </div>
                                <div className="carousel-item">
                                <img className="d-block w-100" src="images/gallery_images/gallery_1.jpeg" alt="Fourth slide" />
                                </div>
                            </div>

                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                


            </div>
        )
    }

}

export default Gallery;
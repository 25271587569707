import React from "react";
import "./App.scss";
import Navbar from "./Components/NavBar/Navbar";
import Carousel from "./Components/Carousel/Carousel";
import Events from "./Components/Events/Events";
import About from "./Components/About/About";
import Gallery from "./Components/Gallery/Gallery";
import Services from "./Components/services/Services";
import ContactUs from "./Components/ContactUs/ContactUs";
import Footer from "./Components/Footer/Footer";
import { Component } from "react";
import Slider from "react-slick";
import Sponsors from "./Components/Sponsors/Sponsors";

class App extends Component {

  constructor(props) {
    super(props);
    this.setImg=this.setImg.bind(this);
  }

  setImg = (a) => {
    document.getElementById(
      "BgImage"
    ).style.background = `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('${document.getElementById("img_"+a).src}')`;
    document.getElementById('TextFrame').innerHTML = document.getElementById("text_"+a).innerHTML;
    document.getElementById('hidden_'+a).classList.remove('d-none')
  }

  render(){
    return (
      <div className="main">
        <Navbar />
        <Carousel />
        <About />
        <Services setImg={this.setImg} />

        <Events /> 
        <Sponsors/>
        <Gallery />
        <ContactUs />
        <Footer setImg={this.setImg}/> 
        
      </div>
    )
  }
}

export default App;

import React, { Component } from 'react';
import './AboutStyle.scss';  




class About extends Component{

    render(){

        return(
            <div className='' id='About'>
                <div className='container text-aligning-black'>
                <h3>ABOUT US</h3>
                <img className='pb-5' src='images/underline.png' />
                <p>We conceptualize, plan, and manage every project from inception to completion, specializing in the development of exceptional events for private and corporate clients.</p>
                <p>Our group of seasoned professionals understands that breakthrough idea are only as good as the discipline supporting them. At B&amp;B, we believe that there is a sublime connection between the mastery of logistics and the creation of astonishment.</p>
                {/* <h3 className='heading pt-5'>THE ROCKING TEAM</h3>
                <img className='pb-5' src='images/underline.png'/>

                    
                        <div class="row mx-auto">
                            <div class="col-4 col-lg-2 mb-3">
                            <img className='bandMembers img-fluid' src='https://picsum.photos/1440/901' />
                            </div>
                            <div class="col-4 col-lg-2 mb-3">
                            <img className='bandMembers img-fluid' src='https://picsum.photos/1440/902' />
                            </div>
                            <div class="col-4 col-sm-6 col-lg-2 mb-3">
                            <img className='bandMembers img-fluid' src='https://picsum.photos/1440/903' />
                            </div>
                            <div class="col-4 col-lg-2 mb-3">
                            <img className='bandMembers img-fluid' src='https://picsum.photos/1440/904' />
                            </div>
                            <div class="col-4 col-lg-2 mb-3">
                            <img className='bandMembers img-fluid' src='https://picsum.photos/1440/905' />
                            </div>
                            <div class="col-4 col-lg-2 mb-3">
                            <img className='bandMembers img-fluid' src='https://picsum.photos/1440/906' />
                            </div>
                        </div> */}
                    </div>
                </div>
            
        )
    }
}


export default About;
import React, { Component } from 'react';
import './ContactUsStyle.scss'
import '../Concert.css';


class ContactUs extends Component {
    render() {
    return(

            <div className='parallax4' id='Contact_Us'>
                <div className='text-aligning-white'>
                    <h3>CONTACT US</h3>
                    <img className='mb-3' src='images/underline.png' />

                    <div className="pb-0 pb-sm-3">
                        <input type='text' placeholder='Full Name' /><br />
                        <input type='email' placeholder='Email' /><br />
                        <input type='text' placeholder='Mobile Number' /><br />
                        <textarea type='text' placeholder='Your Message' /><br />
                        <button className='btn-danger'>Send Message</button>
                    </div>
                </div>
            </div>

    )
    }
}


export default ContactUs;